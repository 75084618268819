import { v4 as uuidv4 } from 'uuid';
import { getCustomerInfo } from '../shopping-carts/bsee-cart/GetCustomerInfo';
import DOMPurify from 'dompurify';
import { maxOffers, cookieName, cookieExpires, bseeAppTypes, cbmAppTypes, cookieNames, geoLocalId, urls } from './constants';
let cartFocusElements: any[] = [];

let firstCartFocusElement: { focus: () => void; }, lastCartFocusElement: { focus: () => void; };
export function setCookie(name: string, value: string, days: number) {
    let expires = '';
    if (days) {
        const date = new Date();
        date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
        expires = '; expires=' + date.toUTCString();
    }
    document.cookie = name + '=' + (value || '') + expires + '; path=/';
}

export function getCookie(name: string) {
    const nameEQ = name + '=';
    const ca = document.cookie.split(';');
    for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) == ' ') c = c.substring(1, c.length);
        if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
    }
    return null;
}

// eslint-disable-next-line no-unused-vars
export function updateCookieId(cname: string, newId: string) {
    const cookie = getCookie(cname);
    const customer = getCustomerInfo();
    let marketId = customer?.marketId ? customer?.marketId : 0;
    if (newId !== geoLocalId) {
        marketId = 0
    }
    const cookieData = `pscCustomerId=${getVisitorIdFromCookie()}&marketId=${marketId}&sessionId=${newId}`

    if (!cookie) {
        return
    }

    setCookie(cookieName, cookieData, cookieExpires);
}

export const getVisitorIdFromCookie = () => {
    const cookie = getCookie(cookieName);
    const cookieCrumbs = cookie?.split('&');
    const cookieCutomerId = cookieCrumbs && cookieCrumbs[0].split('=')[1];

    return cookieCutomerId
}

export const getMarketIdFromCookie = () => {
    const cookie = getCookie(cookieName);
    const cookieCrumbs = cookie?.split('&');
    const cookieMarketId = cookieCrumbs && cookieCrumbs[1].split('=')[1];

    return cookieMarketId
}

export const getSessionIdFromCookie = () => {
    const cookie = getCookie(cookieName);
    const cookieCrumbs = cookie?.split('&');
    const cookieSessionId = cookieCrumbs && cookieCrumbs[2].split('=')[1];
    return cookieSessionId
}

export const isGeoLocalized = () => {
    const sessionId = getSessionIdFromCookie();

    return sessionId === geoLocalId;
};

export const setToHtml = (content: string) => {
    const sanitizedContent = DOMPurify.sanitize(content);

    return { __html: sanitizedContent };
};


export function deleteCookie(cname: string) {
    document.cookie = `${cname}= ; expires = Thu, 01 Jan 1970 00:00:00 GMT; path=/`;
}

export const getHostName = () => {
    return location.host;
};

export const getPathName = () => {
    return location.pathname;
};

export const isLocalHost = () => {
    const origin = location.origin;
    return origin.includes('localhost') ? true : false;
}

export const getOriginName = () => {
    if (isLocalHost()) {
        return urls.prod
    }

    return location.origin;
};

export const cookieFunction = () => {
    const cookie = getCookie(cookieName);
    const customer = getCustomerInfo();

    const marketId = customer?.marketId ? customer?.marketId : 0;
    const cookieData = `pscCustomerId=${uuidv4()}&marketId=${marketId}&sessionId=${customer?.sessionId}`
 

    if (cookie) {
        return;
    }
    setCookie(cookieName, cookieData, cookieExpires);
};

export const  extendCookie = (cookie: any) => {
    if(!cookie){
        return null;
    }

    setCookie(cookieName, `${cookie}`, cookieExpires);
};

export const timeStamp = (date: Date | string) => {
    return Math.floor(new Date(date).getTime() / 1000);
};

export const sortOffers = (offers: any[]) => {
    const sortedOffers = offers?.sort((x: { timeStamp: string }, y: { timeStamp: string }) => (
        timeStamp(x.timeStamp) < timeStamp(y.timeStamp) ? 1 : -1
    )).slice(0, maxOffers);

    return sortedOffers;
};

export const toUsdShorthand = (price: number) => {
    if (price === null || price === undefined) {
        return '';
    }

    return price?.toLocaleString('en-en', {
        style: 'currency',
        currency: 'USD'
    });
};

export const appTypeShouldDisplay = (appData: { applicationName: any; authenticated: any; }): boolean => {
    if (bseeAppTypes.includes(appData.applicationName) && !appData.authenticated) {
        return true;
    }

    if (cbmAppTypes.includes(appData.applicationName)) {
        return true
    }

    return false;
}

export const isArrayEqual = (a: string | any[], b: string | any[]) => {
    // If length is not equal
    if (a.length !== b.length) {
        return false;
    } else {
        // comparing each element of array
        for (let i = 0; i < a.length; i++) {
            if (a[i] !== b[i]) {
                return false;
            }
        }
    }
    return true;
};

const focusFunc = (evt: { keyCode: any; which: any; target: { focus: (() => void) | (() => void); }; preventDefault: () => void; shiftKey: any; }) => {
    const keycode = evt.keyCode || evt.which;
    const handleTabForward = () => {
        if (evt.target === lastCartFocusElement) {
            evt.preventDefault();
            firstCartFocusElement.focus();
        }
    };
    const handleTabBack = () => {
        if (evt.target === firstCartFocusElement) {
            evt.preventDefault();
            lastCartFocusElement.focus();
        }
    };

    switch (keycode) {
        case 9: // TAB KEY
            if (cartFocusElements.length === 1) {
                evt.preventDefault();
                break;
            }
            if (evt.shiftKey) {
                handleTabBack();
            } else {
                handleTabForward();
            }
            break;
        default:
            break;
    }
};

export const focusTrap = (resetFocus = true, revert = false) => {
    const element = document.getElementById('psc');
    let newcartFocusElements: string | any[] = [];

    if (element) {
        newcartFocusElements = Array.from(
            element.querySelectorAll(
                "a, button:not(:disabled), input:not(:disabled), select:not(:disabled), textarea:not(:disabled)"
            )
        );
    }


    if (revert) {
        newcartFocusElements.forEach(ele => {
            ele.setAttribute('tabIndex', "-1")
        })

        return;
    }

    newcartFocusElements.forEach(ele => {
        ele.setAttribute('tabIndex', "0")
    })


    // If arrays are equal no need to continue
    if (isArrayEqual(cartFocusElements, newcartFocusElements)) {
        return false;
    } else {
        cartFocusElements = newcartFocusElements;
    }

    firstCartFocusElement = cartFocusElements[0];
    lastCartFocusElement = cartFocusElements[cartFocusElements.length - 1];

    // Set focus on first element
    if (cartFocusElements.length && resetFocus) {
        firstCartFocusElement.focus();
    }
    cartFocusElements.map((item) => {
        item.removeEventListener('keydown', focusFunc); // Remove event listener so isnt added twice
        item.addEventListener('keydown', focusFunc);
    });
};

export function getVisitorSessionId() {
    const vsCookieName = cookieNames.visitorSessionId + '=';

    let i,
        c;

    const scCookie = getCookie(cookieNames.SC);

    if (scCookie && scCookie.length > 0) {
        const scCookies = scCookie.split('&');

        for (i = 0; i < scCookies.length; i++) {
            c = scCookies[i];

            while (c.charAt(0) === ' ') {
                c = c.substring(1);
            }
            if (c.indexOf(vsCookieName) === 0) {
                return c.substring(vsCookieName.length, c.length);
            }
        }
    }

    return null;
}

export function startWithHttp(url: string | null | undefined) {
    return url && url.startsWith('http');
}