import { Key, FC } from 'react';
import { OfferI } from '../OfferCardInterface';
import { getOriginName, setToHtml, startWithHttp } from '../../../../utils/utility';

const OfferFeatureList: FC<OfferI> = ({ offer }) => (
    <ul className="feature-list">
        {offer?.offerCardCallouts?.map((callout: {
            offerCardCalloutIcon: {
                src: string | undefined; alt: string | undefined;
            };
            offerCardCalloutMessage: any; offerCardCalloutTooltip: string | any[];
        }, i: Key | null | undefined) => (
            <li
                className="feature-list-item"
                key={i}
            >
                {callout.offerCardCalloutIcon &&
                    callout.offerCardCalloutIcon.src && (
                        <span className="feature-list-item-image">
                            <img
                                alt={''}
                                src={startWithHttp(callout.offerCardCalloutIcon.src) ? callout.offerCardCalloutIcon.src : getOriginName() + callout.offerCardCalloutIcon.src}
                                width="30" />
                        </span>
                    )}
                <span
                    className="feature-list-item-description"
                    dangerouslySetInnerHTML={setToHtml(callout.offerCardCalloutMessage)} />
            </li>
        ))}
    </ul>
)

export default OfferFeatureList;